<template>
  <AccountForm />
</template>

<script>
import AccountForm from 'organisms/Forms/Account/Accountform'

export default {
  name: 'AccountTemplate',
  components: {
    AccountForm
  }
}
</script>

<style lang="scss">
</style>
